// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-404-index-jsx": () => import("./../../../src/components/404/index.jsx" /* webpackChunkName: "component---src-components-404-index-jsx" */),
  "component---src-components-editor-panel-manage-sections-layouts-article-index-jsx": () => import("./../../../src/components/EditorPanel/ManageSections/Layouts/Article/index.jsx" /* webpackChunkName: "component---src-components-editor-panel-manage-sections-layouts-article-index-jsx" */),
  "component---src-components-tablet-view-index-jsx": () => import("./../../../src/components/TabletView/index.jsx" /* webpackChunkName: "component---src-components-tablet-view-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

